@import 'styles/theme';

.FeaturedNews {
  .start {
    min-height: 350px;
    @include from('md') {
      min-height: 695px;
    }
  }

  .newsCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: map-get($colors, 'white');
    border-radius: 4px;
    height: 100%;
    @include applyColumnWidth('padding', 3, $mobile);

    @include from('md') {
      justify-content: flex-start;
      @include applyColumnWidth('padding', 2, $desktop);
    }
  }

  .startCard,
  .description {
    max-width: 279px;
  }

  .cta {
    text-decoration: underline;
    margin-top: 12px;

    @include from('md') {
      margin-top: 0px;
    }
  }

  .descriptionContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    
    @include from('md') {
      margin-top: auto;
    }
  }
  
  .description {
    display: none;
    margin-right: auto;

    @include from('md') {
      display: block;
    }
  }

  .publicationDate {
    margin-bottom: 12px;
    opacity: 0.3;
  }

  .image {
    position: relative;
    height: 100%;
  }
}
