@import 'styles/theme';

.LinkList {
  margin: {
    top: 120px;
    bottom: 120px;
  }

  .links {
    grid-column: 2/-2;
  }

  .linkWrapper {
    position: relative;
    max-height: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    &:after,
    &:before {
      position: absolute;
      width: 100%;
      height: 1px;
      background: currentColor;
      opacity: 0.1;
    }
    &:after {
      content: '';
      bottom: 0;
    }
    &:first-child:before {
      content: '';
      top: 0;
    }
    .link {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      div {
        margin: auto 0;
      }
      .icon {
        width: 10px;
        path {
          fill: currentColor;
        }
      }
    }
  }
}
