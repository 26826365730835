@import 'styles/theme';

.SequencedIntro {
  position: relative;

  @include from('md') {
    height: 600vh;
  }

  .stickyContainer {
    @include from('md') {
      position: sticky;
      top: 0px;
      height: 100vh;
    }
  }

  .animatedCanvasContainer {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;

    .animatedCanvas {
      position: sticky;
      top: 20px;
    }
  }
}
