@import 'styles/theme';

.LogoSliderWithText {
  margin-top: 100px;
  margin-bottom: 100px;

  @include from('md') {
    min-height: 574px;
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .title {
    max-width: 288px;
    margin-bottom: 20px;

    @include from('md') {
      margin-bottom: 0px;
      max-width: 440px;
    }
  }

  .text {
    max-width: 288px;
    margin-bottom: 24px;

    @include from('md') {
      margin-bottom: 0px;
    }
  }

  .end {
    margin-top: 24px;

    @include from('md') {
      margin-top: 0px;
    }
  }

  .endWrapper {
    height: 100%;
    padding-bottom: 80%;

    @include from('md') {
      padding-bottom: 0;
    }
  }

  .sliderContainer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: map-get($colors, 'black');
    border-radius: 4px;
    overflow: hidden;
    transition: background-color 0.3s linear;
  }

  .logoSlider,
  .logo {
    @include applyColumnWidth('width', 19, $desktop);

    @include from('md') {
      @include applyColumnWidth('width', 6, $desktop);
    }
  }

  .link, img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .link {
    display: flex;
    align-items: center;
    @include applyColumnWidth('margin-right', 9, $desktop);

    @include from('md') {
      @include applyColumnWidth('margin-right', 6, $desktop);
    }
  }
}
