@import 'styles/theme';

.ProjectCard {
  margin-bottom: 34px;
  // overflow: hidden;
  position: relative;
  // box-shadow:inset 0px 0px 0px 1px #fff;

  .overlayWrapper {
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 101%;
  }

  .overlay {
    position: absolute;
    width: 102%;
    height: 102%;
    left: -1%;
    top: -1%;
    z-index: 2;
  }

  .circle,
  .hoverWord {
    z-index: 1;
  }

  .insideCircle,
  .circle {
    width: 12px;
    height: 12px;
    position: absolute;
    border-radius: 100%;
  }

  .insideCircle {
    background-color: theme($colors, 'flamingo');
  }

  .hoverWord {
    pointer-events: none;
    position: absolute;
    top: 0px;
    width: 120px;
    // height: 150px;
    transform: translate(0, -50%);
    mix-blend-mode: exclusion;

    .words {
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;
      // text-align: center;
      transform: translate3d(24px, -50%, 0);
    }

    .word {
      position: relative;
      display: inline-block;
      z-index: 1;
    }
  }

  .words,
  .circle {
    opacity: 0;
  }

  @include from('md') {
    margin-bottom: 30px;

    &.full-width {
      @include applyColumnWidth('width', 58, $desktop);
    }

    &.half {
      @include applyColumnWidth('width', 28.5, $desktop);
    }

    &.quarter {
      @include applyColumnWidth('width', 13.75, $desktop);
    }
  }

  @include applyColumnWidth('width', 28, $mobile);

  .infos {
    z-index: 100;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background-color: black;
    padding: 20px 0 40px 0;
    display: flex;
    flex-direction: column;
  }

  .leftCorner {
    left: 0px;
    transform: scaleX(-1);
  }

  .rightCorner {
    right: 0;
  }

  // .image {
  //   //overflow: hidden;
  //   // margin-bottom: 15px;
  //   // @include from('md') {
  //   //   margin-bottom: 20px;
  //   // }
  // }

  .category {
    margin-top: 4px;
    opacity: 0.6;
  }
}
