@import 'styles/theme';

.FeaturedType {
  margin-top: 100px;
  margin-bottom: 100px;

  @include from('md') {
    margin-top: 200px;
    margin-bottom: 200px;
  }
}
