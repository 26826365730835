@import 'styles/theme';

.ProjectsGrid {
  &:first-child {
    margin-top: 158px;

    @include from('md') {
      margin-top: 334px;
    }
  }

  .title {
    margin-bottom: 20px;

    @include from('md') {
      margin-top: 34px;
      @include applyColumnWidth('margin-left', -0.5, $desktop);
    }
  }

  .grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .project {
    @include applyColumnWidth('margin-left', 1, $mobile);

    @include from('md') {
      @include applyColumnWidth('margin-left', 1, $desktop);
    }
  }
}
