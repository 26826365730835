@import 'styles/theme';

.TwoColumnsIntro {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;

  @include from('md') {
    min-height: 700px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .starIconContainer {
    perspective: 1000px;
  }

  .starIconGroup {
    position: relative;
    width: 87px;
    display: none;
    transform-style: preserve-3d;
    backface-visibility: visible;
    will-change: transform;

    @include from('md') {
      display: block;
    }
  }

  .starIcon {
    position: absolute;
    width: 87px;
    transform: translateZ(1px);
    top: 0px;
    left: 0px;

    &:first-child {
      position: relative;
    }

    &:nth-child(2) {
      transform: translateZ(-4px);
      filter: invert(1);
    }

    &:nth-child(3) {
      transform: translateZ(-8px);
    }

    path {
      fill: currentColor;
    }
  }

  .startRichText {
    max-width: 287px;
    @include from('md') {
      max-width: 440px;
    }
  }

  .endRichText {
    margin-top: 20px;
    max-width: 330px;

    @include from('md') {
      margin-top: 0px;
    }
  }
}
