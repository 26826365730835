@import 'styles/theme';

.ThirdSequence {
  .stickyContainer {
    @include from('md') {
      align-items: flex-start;
    }
  }

  .thirdSequenceContainer {
    overflow: hidden;

    @include from('md') {
      visibility: hidden;
    }

    .image {
      width: 100%;
    }

    .ratio {
      width: 200%;
      position: relative;
      border-radius: 50%;
      grid-column: 1 / 31;
      overflow: hidden;
      margin-top: -100%;
      margin-left: 50%;
      transform: translateX(-50%);

      @include from('md') {
        width: 100%;
        transform: initial;
        margin-left: initial;
        margin-top: -50%;
        grid-column: 2 / 60;
      }
    }
  }
}
