@import 'styles/theme';
@import './grid-functions.module';

.NewsGrid {
  margin: 120px 0 60px;

  @include from('md') {
    margin: 200px 0 154px;
  }

  .title {
    margin-bottom: 47px;
  }

  .newsCard {
    &:after {
      content: '';
      display: none;
      position: absolute;
      width: calc(#{vw(100)} - #{getGridColumnWidth(2, $mobile)});
      height: 1px;
      background-color: currentColor;
      opacity: 0.1;
    }

    &:nth-child(odd) {
      grid-column: 1 / span 15;

      &:not(:first-child):after {
        display: block;
        top: -30px;
      }
    }

    &:nth-child(even) {
      grid-column: 16 / span 15;
    }

    // Grid rhythm
    @include from('sm') {
      $maxGridItems: 3;

      @include computeGridItems($maxGridItems, $mobile);
    }

    @include from('md') {
      $maxGridItems: 4;

      @include computeGridItems($maxGridItems, $desktop);
    }

    @include from('lg') {
      $maxGridItems: 5;

      @include computeGridItems($maxGridItems, $desktop);
    }
  }
}
