@import 'styles/theme';

$desktop-padding-14: 0.5835;

.NewsCard {
  @include applyColumnWidth('padding-left', 1, $mobile);
  @include applyColumnWidth('padding-right', 1, $mobile);
  position: relative;
  margin-bottom: 60px;

  @include from('md') {
    display: flex;
    flex-direction: column;
    margin-bottom: 46px;

    &:nth-child(n) {
      @include applyColumnWidth('padding-left', $desktop-padding-14, $desktop);
      @include applyColumnWidth('padding-right', $desktop-padding-14, $desktop);
    }
    &:nth-child(5n + 1) {
      @include applyColumnWidth('padding-left', 1, $desktop);
    }

    &:nth-child(5n + 5) {
      @include applyColumnWidth('padding-right', 1, $desktop);
    }
  }

  .image {
    margin-bottom: 30px;
    @include from('md') {
      margin-bottom: 24px;
    }
  }

  .pusblishedAt {
    margin-bottom: 10px;
    opacity: 0.3;
    @include from('md') {
      margin-bottom: 12px;
    }
  }

  .separator {
    margin-left: 5px;
    margin-right: 5px;
  }
  .name {
    margin-bottom: 10px;
    font-weight: map-get($fontWeights, 'medium');
    line-height: 1.2;

    @include from('md') {
      max-width: 265px;
      margin-bottom: 100px;
    }
  }

  .description {

    p {
      margin-bottom: 20px;
    }

    @include from('md') {
      margin-top: auto;
    }
  }
}
