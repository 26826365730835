@import 'styles/theme';

.SeeMore {
  margin-bottom: 15px;

  @include from('md') {
    margin-bottom: 20px;
  }

  .container {
    grid-column: 2 / 30;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include from('md') {
      grid-column: 2 / 60;
    }
  }

  .cta {
    margin-left: auto;
  }
}
