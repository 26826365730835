@import 'styles/theme';

.SplittedRichTextSlice {
  margin-top: 100px;
  overflow: hidden;

  @include from('md') {
    margin-top: 200px;
  }

  .defaultColumnSize {
    @include from('md') {
      max-width: 440px;
    }
  }

  .largeColumnSize {
    @include from('md') {
      max-width: 640px;
    }
  }

  .start,
  .end {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    @include clearMarginForBlocks();

    @include from('md') {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  ol {
    li {
      padding-left: 30px;
    }
  }

  ul {
    list-style: none;
    margin: 0px 0;

    @include from('sm') {
      margin: 40px 0;
    }

    li {
      position: relative;
      font-size: 16px;
      padding: 13px 0px;

      &:after,
      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        opacity: 0.1;
        left: 0px;
      }

      &:after {
        top: 0px;
      }

      &:before {
        bottom: 0px;
        display: none;
      }
      
      &:last-of-type {
        &:before {
          display: block;
        }
      }
    }
  }
}
