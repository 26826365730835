@import 'styles/theme';

.BigLink {
  position: relative;
  //min-height: 600px;
  color: currentColor;
  margin-top: 70px;
  margin-bottom: 70px;
  font-size: 40px;
  height: 300px;


  @include from('md') {
   // min-height: 871px;
    margin-top: 21px;
    margin-bottom: 21px;
    font-size: 65px;
    height: 600px;
    font-weight: 400;
  }

  .border {
    position: absolute;
    top: 0;
    bottom: 0;
    border-width: 1px;
    border-style: solid;
    pointer-events: none;
    z-index: 1;

    @include applyColumnWidth('left', 1, $mobile);
    @include applyColumnWidth('right', 1, $mobile);

    @include from('md') {
      @include applyColumnWidth('left', 1, $desktop);
      @include applyColumnWidth('right', 1, $desktop);
    }

    &:after {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: 1px solid currentColor;
      opacity: 0.1;
      border-radius: 4px;
    }
  }

  .link {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    will-change: transform;

    @include from('md') {
      display: inline-block;
      width: 100%;
    }
  }

  .linkContainer {
    perspective: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    grid-column: 2 / 30;

    @include from('md') {
      justify-content: flex-start;
      grid-column: 2 / 60;
    }
  }

  .circle,
  .innerCircle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    // Border-radius zIndex fix for iOS
    z-index: 1;

    @include from('md') {
      width: 60px;
      height: 60px;
    }
  }

  .circle {
    position: relative;
    display: inline-block;
    margin-right: 24px;
    overflow: hidden;
    vertical-align: middle;

    @include from('md') {
      @include applyColumnWidth('margin-left', 10, $desktop);
    }
  }

  .label {
    display: inline-block;
    vertical-align: middle;

    @include from('md') {
      width: 96px;
    }
  }

  .smallScreenLabel {
    @include from('md') {
      display: none;
    }
  }

  .largeScreenLabel {
    display: none;

    @include from('md') {
      display: inline-block;
      width: auto;
    }
  }

  .innerCircle {
    display: block;
    background-color: map-get($colors, 'flamingo');
  }

  .backgroundIcon {
    width: 150%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(48deg) scale(1.3);

    @include from('md') {
      width: 100%;
    }

    path {
      stroke: currentColor;

      @include from('md') {
        stroke-width: 0.45;
      }
    }
  }

  .background {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    will-change: transform;
  }
}
