@import 'styles/theme';

.AnimatedCanvas {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: none;

  @include from('md') {
    display: block;
  }

  .canvasContainer {
    width: 100%;
    height: 100vh;
    position: sticky;
    top: 0px;
    display: flex;
    align-items: center;

    .canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
