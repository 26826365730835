@import 'styles/theme';

.RichTextList {
  margin-top: 80px;
  margin-bottom: 80px;

  @include from('md') {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .richText {
    grid-column: 2 / 29;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0px;
    }

    @include from('sm') {
      grid-column: span 30 / auto;
      margin-bottom: 100px;
      @include applyColumnWidth('margin-right', 1, $desktop);
      @include applyColumnWidth('margin-left', 1, $desktop);
    }

    @include from('xl') {
      grid-column: span 15 / auto;
      margin-bottom: 100px;
      @include applyColumnWidth('margin-right', 1, $desktop);
      @include applyColumnWidth('margin-left', 1, $desktop);
    }
  }
}
