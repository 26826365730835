@use "sass:math";
@import 'styles/theme';

.LogoGrid {
  margin: {
    top: 120px;
    bottom: 120px;
  }

  .title {
    position: relative;
    margin: 14px 0 100px 0;
    grid-column: 2/-2;
  }

  .ul {
    overflow: hidden;

    .logo {
      grid-column: 2/-2;
      height: 180px;
      margin-bottom: 28px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &:nth-child(odd) {
        grid-column: 2/16;

        &:before,
        &:after {
          content: '';
          position: absolute;
          background-color: currentColor;
          opacity: 0.1;
        }

        &:after {
          bottom: -14px;
          left: 0;
          width: calc(#{vw(100)} - #{getGridColumnWidth(2, $mobile)});
          height: 1px;
        }

        &:before {
          right: 0;
          width: 1px;
          top: 0;
          bottom: 0;
        }
      }

      &:nth-child(even) {
        grid-column: 16/-2;
      }
    }
  }

  @include from('md') {
    margin: {
      top: 200px;
      bottom: 200px;
    }

    .ul {
      .logo {
        height: 250px;

        &:before {
          content: '';
          height: 100%;
          width: 1px;
          background: currentColor;
          opacity: 0.1;
          top: 0;
          right: 0;
          position: absolute;
        }

        &:nth-child(4n + 1) {
          grid-column: 1 / span 15;

          &:after {
            width: calc(#{vw(100)} - #{getGridColumnWidth(2, $desktop)});
            left: #{getGridColumnWidth(1, $desktop)};
          }
        }

        &:nth-child(4n + 2) {
          grid-column: 16 / span 15;

          &:after {
            display: none;
          }
        }

        &:nth-child(4n + 3) {
          grid-column: 31 / span 15;

          &:after {
            display: none;
          }
        }

        &:nth-child(4n + 4) {
          grid-column: 46 / span 15;

          &:before {
            display: none;
          }

          &:after {
            display: none;
          }
        }
      }
    }
  }
}
