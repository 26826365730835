@import 'styles/theme';

.Sequence {
  @media screen and (max-width: #{theme($screens, 'md') - 1px}) {
    opacity: 1 !important;
  }

  @include from('md') {
    position: absolute;
    top: 0px;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
  }
}
