@import 'styles/theme';

.section {
  height: 200vh;
}

.FirstSequence {
  margin-top: 25px;
  margin-bottom: 25px;

  @include from('md') {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .start,
  .end {
    grid-column: 2 / 30;
    .animatedSequence {
      display: none;

      @include from('md') {
        display: block;
      }
    }
    .deviceSequence {
      display: block;

      @include from('md') {
        display: none;
      }
    }
  }

  .start {
    margin-bottom: 35px;

    @include from('md') {
      margin-bottom: 0px;
      grid-column: 2 / 25;
    }
  }

  .end {
    @include from('md') {
      visibility: hidden;
      grid-column: 27 / 60;
    }
  }

  .ratio {
    width: 100%;
    border-radius: 50%;
    overflow: hidden;

    @include from('md') {
      max-width: calc(100vh - 150px);
    }
  }

  .startBottom {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    @include from('md') {
      display: flex !important;
      @include applyColumnWidth('padding-right', 2, $desktop);
      flex-direction: row;
    }

    .bottomRichText {
      width: 100%;
      @include from('md') {
        width: 50%;
      }
    }
  }

  .endSecondColumn {
    margin-left: auto;
  }
}
