@import 'styles/theme';

.HeroWithText {
  position: relative;
  margin-top: 100px;
  align-items: flex-end;

  .link {
    display: block;
    grid-column: 2 / 30;

    @include from('md') {
      grid-column: 2 / 60;
    }
  }

  .mediaWrapper {
    position: relative;
    display: block;
    grid-column: 2 / 30;

    @include from('md') {
      grid-column: 2 / 60;
    }
  }

  .overlayWrapper {
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 101%;

    grid-column: 2 / 30;

    @include from('md') {
      grid-column: 2 / 60;
    }
  }

  .overlay {
    position: absolute;
    width: 102%;
    height: 102%;
    left: -1%;
    top: -1%;
    z-index: 2;
  }

  .infos {
    z-index: 100;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background-color: black;
    padding: 16px 0 40px 0;
    display: flex;
    flex-direction: column;
  }

  .arrow {
    position: relative;
    top: -4px;
    width: 1.4em;
    margin-right: 10px;
  }

  .leftCorner {
    left: 0px;
    transform: scaleX(-1);
  }

  .rightCorner {
    right: 0;
  }

  // .image {
  //   //overflow: hidden;
  //   // margin-bottom: 15px;
  //   // @include from('md') {
  //   //   margin-bottom: 20px;
  //   // }
  // }

  .title {
    position: relative;
  }

  .category {
    margin-top: 4px;
    opacity: 0.6;
  }

  &:first-child {
    margin-top: 158px;
  }

  @include from('md') {
    margin-top: 200px;

    &:first-child {
      margin-top: 449px;
    }
  }
  .start {
    margin-bottom: 20px;
    @include from('md') {
      margin-bottom: 0px;
    }
  }

  .richTextClassName {
    max-width: 380px;
  }

  .singleColumnRichText {
    grid-column: 2 / 30;

    @include from('md') {
      max-width: 1350px;
      grid-column: 2 / 60;
    }
  }

  .image {
    position: relative;
    grid-column: 2/ 30;
    margin-top: 30px;

    @include from('md') {
      margin-top: 17px;
      grid-column: 2 / 60;
    }
  }

  @include switchDesktopMobileClasses();

  &.noMarginTop {
    margin-top: 0px;

    @include from('sm') {
      margin-top: 40px;
    }
  }
}
