@import 'styles/theme';

.ImageSlider {
  overflow: hidden;

  margin-top: 30px;
  margin-bottom: 30px;

  @include from('md') {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .ratio {
    pointer-events: none;

    @include applyColumnWidth('width', 23, $mobile);
    @include applyColumnWidth('margin-right', 1, $mobile);

    @include from('md') {
      @include applyColumnWidth('width', 40, $desktop);
      @include applyColumnWidth('margin-right', 1, $desktop);
    }

    &.portrait {
      @include applyColumnWidth('width', 20, $mobile);
      @include applyColumnWidth('margin-right', 1, $mobile);

      @include from('md') {
        @include applyColumnWidth('width', 12, $desktop);
        @include applyColumnWidth('margin-right', 1, $desktop);
      }
    }
  }
  .slider {
    width: 100%;
    grid-column: 2 / 30;

    @include from('md') {
      grid-column: 2 / 60;
    }
  }
}
