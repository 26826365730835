@use "sass:math";

@mixin firstItemBorder() {
  &:not(:first-child):after {
    display: block;
    top: -23px;
  }

  &:after {
    display: block;
    top: -23px;
  }
}

@mixin hideOtherItemsBorder() {
  &:not(:first-child):after {
    display: none;
  }
}

@mixin setNewRowBorderProps($maxGridItems) {
  &:not(:nth-child(#{$maxGridItems}n)):before {
    content: '';
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    width: 1px;
    background: currentColor;
    opacity: 0.1;
  }
}

@mixin computeGridItems($maxGridItems, $viewportTarget) {
  $gridSpan: math.div(theme($viewportTarget, 'columns'), $maxGridItems);

  &:after {
    width: calc(#{vw(100)} - #{getGridColumnWidth(2, $viewportTarget)});
  }

  @for $i from 0 through ($maxGridItems - 1) {
    $currentValue: $i + 1;
    $computedGridSpan: ($gridSpan * $i) + 1;
    $isFirst: $i == 0;

    &:nth-child(#{$maxGridItems}n + #{$currentValue}) {
      grid-column: #{$computedGridSpan} / span #{$gridSpan};

      @if $isFirst {
        @include firstItemBorder();
      } @else {
        @include hideOtherItemsBorder();
      }
    }
  }

  @include setNewRowBorderProps($maxGridItems);
}
