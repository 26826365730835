@import 'styles/theme';

.SplittedSection {
  .richText {
    ol {
      li {
        display: inline-flex;
        &:before {
          margin-bottom: 2px;
        }

        @include from('md') {
          &:before {
            margin-bottom: 7px;
          }
        }
      }
    }
  }
}
