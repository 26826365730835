@import 'styles/theme';

.SecondSequence {
  margin-top: 25px;
  margin-bottom: 25px;

  .animatedSequence {
    display: none;

    @include from('md') {
      display: block;
    }
  }
  .deviceSequence {
    display: block;

    @include from('md') {
      display: none;
    }
  }

  @include from('md') {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .start,
  .end {
    grid-column: 2 / 30;
  }

  .start {
    margin-bottom: 17px;

    @include from('md') {
      visibility: hidden;
      grid-column: 2 / 35;
      margin-bottom: 0px;
    }
  }

  .end {
    max-width: 480px;

    @include from('md') {
      grid-column: 36 / 60;
    }
  }

  .endBottom {
    margin-top: 92px;

    @include from('md') {
      margin-top: 32px;
    }
  }

  .square {
    width: 100%;
    background-color: map-get($colors, 'black');
    @include from('md') {
      max-width: calc(100vh - 150px);
    }
  }

  .circle {
    width: 100%;
    background-color: map-get($colors, 'white');
    border-radius: 50%;
  }
}
